.common-wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: fit-content;

    .common-block-e {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .common-left-block-opened {
            display: block;
            overflow: hidden;
            align-items: flex-start;
            width: 100%;
            height: 40vh;
            font-size: 17px;
            border-radius: 2px;
            transition: height 0.5s ease-in-out; }


        .common-left-block-e {
            display: block;
            overflow: hidden;
            align-items: flex-start;
            width: 100%;
            height: 44px;
            font-size: 17px;
            transition: all 0.5s ease-in-out; }

        .common-left-block-e:hover {
            box-shadow: 1px 1px 10px rgb(26, 25, 25); }

        .common-clicker {
            margin-top: 6px;
            display: flex;
            width: 100%;
            height: 32px;
            font-weight: 700;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            user-select: none; }

        .common-headline {
            position: relative;
            height: 16px;
            font-family: Geometria;
            font-size: 0.78vw;
            font-weight: 400;
            padding:  0 20px;
            display: flex;
            align-items: center;
            margin-left: 14px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;
            scolor:rgba(24, 28, 32, 1) {} }

        .common-Arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-right: 1vw;
            transition: all 0.2s ease-in-out; }
        .down {
            transform: rotate(180deg); }

        .common-click-res {
            display: flex;
            flex-direction: column;
            margin-top: 6px;
            width: 100%;
            height: fit-content; }

        .common-block-input {
            position: relative;
            display: flex;
            align-items: flex-start;
            width: 12.5vw;
            height: 40px;
            margin-top: 7px;
            margin-left: 2vw;
            border-radius: 2p;
            background: #FFFFFF;
            border: 1.5px solid rgba(128, 128, 128, 0.521);
            filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25)); }

        .common-input-field {
            margin-top: 5px;
            display: flex;

            .common-Loupe {
                display: flex;
                align-items: center;
                width: 2vw;
                margin-left: 0.7vw; }

            .common-input {
                position: relative;
                border: 0;
                outline: 0;
                width: 100%;
                margin-left: 7px; } } }

    .common-marked {
        text-align: center;
        display: block;
        width: 100%;
        height: 180px;
        overflow-y: scroll;
        cursor: pointer;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .common-clicked-field {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 20px;
            color: black;
            font-weight: 500;
            width: 80%;
            height: fit-content;
            margin-top: 10px;
            margin-left: 24px;

            .common-marked-title {
                position: relative;
                width: 80%;
                height: fit-content;
                font-weight: 600;
                font-size: 1vw;
                font-family: Geometria;
                margin-left: 4px; } } }

    .common-list {
        display: block;
        position: absolute;
        top: 120px;
        left: 15px;
        margin-top: 5px;
        width: 230px;
        max-height: 175px;
        height: fit-content;
        overflow-y: scroll;
        font-family: Geometria;
        font-weight: 300;
        z-index: 20;
        cursor: pointer;
        -ms-overflow-style: none;
        scrollbar-width: none;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        overflow-x:hidden {}
        text-align: center;

        .common-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: black;
            position: static;
            min-height: 40px;
            top: 8px;
            padding: 7px 5px;

            &:hover {
                background: rgba(138, 153, 168, 0.15); } } } }
