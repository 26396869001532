.proj-wrapper {
    position: relative;
    height: 95vh;
    display: flex;
    width: 65vw;
    flex-direction: column;
    margin-left: 0.5vw;
    font-style: Geometria;

    .proj-navbar {
        /* border:1px solid red; */
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid rgb(150, 141, 141);
        height: 8.5vh;
        width: 65vw;
        margin-left: 0.6vw;

        .proj-nav-common {
            /* border: 1px solid black; */
            display: flex;
            position: relative;
            justify-content: center;
            width: 12vw;
            /* left: 7vw; */ }

        .proj {
            align-items: center;
            justify-content: center;
            left: 0vw;
            height: 8.5vh;
            width: fit-conten;
            cursor: pointer;
            margin-right: 2vw;
            user-select: none; }

        .choosed {
            padding-top: 0.5vh;
            border-bottom: 4px solid #0074AB; }

        .proj-nav-names {
            height: 16px;
            font-family: Geometri;
            font-weight: 400;
            font-size: 0.78vw;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;
            color: rgb(0, 0, 0);
            margin-top: 4px;
            margin-right: 0; } }

    .shower-teams {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        top: 10vh;
        height: 50vh;
        width: 72vw;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .team {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #fff;
            height: 12.2vh;
            width: 14.5vw;
            margin: 0.6vw;
            box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
            border-radius: 3px; }

        .shower-team-headline {
            position: relative;
            display: flex;
            align-items: center;
            font-family: Geometria;
            font-size: 0.8vw;
            width: 100%;
            margin-top: 1.2vh;
            padding-left: 1vw; }

        .shower-teamate {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            height: 8.5vh;
            padding-top: 0.8vh;
            width: 100%; }

        .teamate-pic {
            width: 2.5vw;
            height: 2.5vw;
            border-radius: 50%;
            margin-left: 0.8vw;
            margin-top: 0.2vw; }

        .teamate-img {
            width: 2.5vw;
            border-radius: 50%; }

        .teamate-name {
            display: flex;
            align-items: center;
            height: 4vh;
            font-size: 1.1vw;
            width: 65%;
            margin-left: 0.5vw; }

        .teamate-role {
            display: flex;
            align-items: center;
            margin-left: 4vw;
            margin-top: -3vh;
            width: 60%;
            height: 2vh;
            font-size: 0.8vw; } } }
