.NavBar-Base {
    position: relative;
    display: flex;
    width: 100%;
    height: 10vh;
    // border: 1px solid red

    .NavBar-modumOps {
        position: relative;
        left: 1.9vw;
        top: 4.5vh;
        width: 9.2vw;
        height: 2.5vh;
        font-family: Geometria;
        font-weight: 500;
        font-size: 1.6vw;
        line-height: 20px; } }
