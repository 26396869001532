.pag-pagination {
    display: flex;
    padding-left: 4vw;
    align-items: center;
    margin-top: 7vh;
    height: 7vh;
    user-select: none; }

.pag-quantity {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 3.5vw; }
