body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif {}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace {} }


::-webkit-scrollbar {
    width: 0;
    height: 0; }

@font-face {
  font-family: "Geometria";
  src: url("./Fonts/Geometria/Geometria-Light_0.otf") format("truetype");
  font-style: normal;
  font-weight: normal; }
