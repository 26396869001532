.Loader-background {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 255, 0.3);
    z-index: 10;

    .loader {
        width: 100px;
        height: 100px; } }
