.search-wrapper {
    position: relative;
    height: 95vh;
    display: flex;
    width: 69vw;
    flex-direction: column;
    /* margin-left: 0.5vw; */
    font-style: Geometria; }

.search-wholestuff {
    position: relative;
    display: block;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh; }

/* Navbar */
.search-navbar {
    position: fixed;
    display: flex;
    /* border: 1px solid black; */
    align-items: center;
    justify-content: center;
    width: 65vw;
    height: 6vh; }


.search-nav-common {
    /* border: 1px solid black; */
    display: flex;
    position: relative;
    justify-content: center;
    width: 13vw;
    /* left: 7vw; */ }

.search-nav-names {
    height: 16px;
    font-family: Geometria;
    font-weight: 400;
    font-size: 0.78vw;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;
    color: rgb(0, 0, 0);
    margin-top: 4px;
    margin-right: 10px;
    padding-left: 0vw; }

.search-nav-common:first-child {
    padding-left: 1vw; }

.serach-results {
    /* border: 1px solid black; */
    position: relative;
    display: block;
    flex-direction: column;
    margin-top: 6vh;
    width: 70vw;
    height: 95%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; }

.serach-results::-webkit-scrollbar {
    width: 0.8vw;
    background: rgba(230, 230, 230, 0.175);
    border-radius: 2px; }

.serach-results::-webkit-scrollbar-thumb {
    background: rgba(160, 160, 160, 0.425);
    height: 5vh;
    border-radius: 2px; }

.search-spiner {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
    top: 20vh;
    left: 30vw; }


.search-list {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: 68vw;
    min-height: 48px;
    border-top: 2px solid rgba(187, 187, 187, 0.527); }

.search-elems-wrapper {
    /* border: 1px solid rgb(38, 0, 128); */
    display: flex;
    height: fit-content;
    width: 100%;
    padding-left: 4vw;
    font-family: Geometria;
    color:  #000000;
    font-size: 1.05vw;
    font-weight: 400; }


.search-elems-wrapper:hover {
    background: rgb(250, 255, 245); }

.search-name-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12vh;
    width: 15vw;
    /* margin-left: 0.8vw;  */
    padding-top: 0.5vh; }


.search-avatar {
    position: relative;
    margin-right: 0.6vw;
    width: 2.1vw;
    border-radius: 50%;
    cursor: pointer; }

.search-clickPlace {
    width: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center {}
    cursor: pointer;
    user-select: none; }

.custom-checkbox {
    position: absolute;
    opacity: 0; }

.custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none; }

.custom-checkbox+label::before {
    content: '';
    display: inline-flex;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    background-position: left;
    background: url('../../jsxIcons/Checkbox.svg') no-repeat;
    box-sizing: border-box;
    border-radius: 2px; }

.custom-checkbox:checked+label::before {
    border: 0;
    box-shadow: 0 0 0;
    width: 24px;
    height: 24px;
    background-position: left;
    background: url('../../jsxIcons/Marked.svg') no-repeat; }

.search-fullName {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    width: 8vw;
    text-align: center; }

.search-elems {
    /* border: 1px solid green; */
    display: block;
    position: relative;
    width: 39vw;
    height: fit-content;
    margin-left: 10px;
    /* left: 1.2vw; */
    text-align: center; }

.search-elems-childs {
    margin-top: -3vh;
    border-bottom: 1.5px solid rgb(216, 200, 200);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4vh;
    padding-bottom: 0.8vh; }

.search-elems-childs:first-child {
    height: 0;
    border: 0; }

.search-elems-childs:last-child {
    border-bottom: 0; }

.search-each-childs {
    width: 13vw;
    padding: 0.5vh 1vw;
    /* border: 1px solid black; */
    height: fit-content; }
