.curPos-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1vh;
    padding-left: 0.8vw;
    height: 5vh;
    cursor: pointer;
    user-select: none;

    .curPos-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 1.7vw;
        height: 3vh;
        border-radius: 2px; }

    .curPos-title {
        font-family: Geometria;
        width: 11vw;
        font-size: 1.05vw;
        margin-left: 0.4vw;
        font-weight: 900; } }
