.projects-wrapper {
    border-top: 1.5px solid rgba(207, 207, 207, 0.856);
    width: 100%;
    height: 32vh;
    border-radius: 2px; }

.projects-headline {
    position: relative;
    font-family: Geometria;
    font-size: 0.78vw;
    font-weight: 40;
    padding-top:  2vh;
    padding-left: 1.5vw;
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;
    color:rgba(24, 28, 32, 1) {} }

.projects-results {
    display: block;
    overflow: scroll;
    height: 26vh;
    margin-top: 1vh; }

.projects-results-lines {
    font-family: Geometria;
    font-size: 0.95vw;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 1.5vw;
    width: 100%;
    height: 6.5vh; }
