.Nav-Menu-User-Base-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 90vh;
    width: 100%;

    .Nav-Menu-contexts {
        border-top:  1px solid #D1D2D3;
        border-bottom: 1px solid #D1D2D3;
        width: 87%;
        height: fit-content;
        padding: 1.2vh 0;
        border-radius: 2px; }

    .Nav-Menu-list {
        display: flex;
        align-items: center;
        position: relative;
        height: 5.5vh;
        width: 100%;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            width: 108%;
            margin-left: -1vh;
            box-shadow: 0.5px 0.5px 10px #606B75;
            transition: all 0.3s ease-in-out; } }

    .Nav-Menu-Icon {
        position: relative;
        display: flex;
        align-items: center;
        width: 3.4vh;
        height: 3.4vh;
        margin-left: 0.9vw; }

    .list-str {
        position: relative;
        display: flex;
        align-items: center;
        width: 10vw;
        height: 3.5vh;
        margin-left: 0.2vw;
        font-family: Geometria;
        font-size: 0.95vw;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.01em;
        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;
        color: #3F3E3E; }


    .facebook {
        display: flex;
        position: relative;
        height: 51.75vh;
        margin-top: 1.5vh;
        width: 87%; }

    .profiles {
        height: 15vh;
        padding-top: 2vh; }

    .profList {
        background: rgb(168, 255, 203 );
        border-radius: 10px; }

    .Nav-Menu-signOut {
        display: flex;
        align-items: center;
        position: relative;
        height: 9.5vh;
        padding-left: 0.9vw;
        width: 100%;
        background: #F0F3F5;

        .Nav-Menu-avatar {
            border-radius: 50%;
            width: 3.3vw;
            height: 3.3vw; }

        .userName {
            display: flex;
            align-items: center;
            font-family: Geometria;
            font-weight: 900;
            font-size: 1vw;
            padding-left: 0.5vw;
            width: 8.8vw; }

        .signOut-str {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 0.2vw;
            width: 2vw;
            height: 3.5vw;
            cursor: pointer; } } }
